export const EVENT_NAMES = {
  SEARCH: 'search',
  REWARDS_TOGGLE: 'rewards_toggle',
  MAP_REFRESH: 'map_refresh',
  MEMBER_CARD_RESCANNED: 'member_card_rescanned',
  MEMBER_CARD_RESCAN_FAILED: 'member_card_rescan_failed',
  MEMBER_CARD_OPENED: 'member_card_opened',
  MEMBER_CARD_REMOVED: 'member_card_removed',
  BARCODE_SCAN_ERROR: 'barcode_scan_error',
  ATTEMPTED_BARCODE_SCAN: 'attempted_barcode_scan',
  BARCODE_SCAN_SUCCESS: 'barcode_scan_success',
};
