import { Capacitor } from '@capacitor/core';
import { Geolocation as NativeGeoLocation } from '@capacitor/geolocation';

export type TypeGeoPosition = {
  coords: {
    latitude: number;
    longitude: number;
  };
};

export class Geolocation {
  async getCurrentLocation(): Promise<TypeGeoPosition | null> {
    const hasPermissions = await this.checkPermissions();

    if (hasPermissions) {
      const location = await NativeGeoLocation.getCurrentPosition();
      return {
        coords: {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        },
      };
    } else {
      throw new Error('Unable to get location without permissions');
    }
  }

  async checkPermissions(): Promise<boolean> {
    try {
      // This function is not implemented on web. Just get the position and handle errors from there correctly
      // In most cases the browser will automatically prompt for permissions if the location is requested, if rejected it will throw on the request.
      if (Capacitor.getPlatform() === 'web') return true;

      const permissionStatus = await NativeGeoLocation.requestPermissions();

      return permissionStatus.location === 'granted' || permissionStatus.coarseLocation === 'granted';
    } catch (err) {
      return false;
    }
  }
}
