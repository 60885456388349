import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {

  constructor(
    private platform: Platform,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    // Skips the onboarding screens on desktop or web...    
    if ((this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
      this.navCtrl.navigateRoot('/home', { animated: false, replaceUrl: true});
    }
    else {
      this.navCtrl.navigateRoot('/find-fuel', { animated: false, replaceUrl: true});
    }
  }

}
