<ion-app *ngIf="(start$ | async) === true" class="scanner-hide">
  <ion-router-outlet [swipeGesture]="false"></ion-router-outlet>
</ion-app>

<div id="barcode-scanner" class="scanner-ui">
  <div *ngIf="(isBarcodeValidating$ | async) === true" class="barcode-spinner">
    <ion-spinner color="light" name="crescent"></ion-spinner>
  </div>
  <div class="barcode-info">
    <p>Position your member card&apos;s barcode in the box below</p>
  </div>
  <div class="square surround-cover">
    <div class="barcode-scanner--area--inner"></div>
      <div class="barcode-scanner--area--outer surround-cover">
    </div>
  </div>
  <ion-button class="barcode-scanner-close" size="large" (click)="closeBarcodeScanner()">
    <ion-icon class="barcode-close-icon" name="close-outline"></ion-icon>
    Close
  </ion-button>
</div>
