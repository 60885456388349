// barcode.service.ts
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Preferences } from '@capacitor/preferences';
import { Dialog } from '@capacitor/dialog';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

import { AnalyticsService } from './analytics.service';
import { EVENT_NAMES } from './analytics.constants';
import { BarcodeValidationService } from '../api/services/barcode-validation.service';

@Injectable({
  providedIn: 'root',
})
export class BarcodeService {
  public isValidating = new BehaviorSubject<boolean>(false);
  public barcode$ = new BehaviorSubject<string>('');
  public memberNumber$ = new BehaviorSubject<string>('');

  constructor(private analyticsService: AnalyticsService, private barcodeValidationService: BarcodeValidationService) {
    this.getBarcode().then((res) => {
      this.barcode$.next(res.value);
    });
    this.getMemberNumber().then((res) => {
      this.memberNumber$.next(res.value);
    });
  }

  public isNewScan = new BehaviorSubject<boolean>(true);
  public errorMessage = new BehaviorSubject<string>('');

  async scanBarcode$() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        this.setIsNewScan(true);
        this.setErrorMessage('');
        const permission = await BarcodeScanner.checkPermission({
          force: true,
        });

        if (permission.granted) {
          BarcodeScanner.hideBackground();

          this.analyticsService.trackEvent(EVENT_NAMES.ATTEMPTED_BARCODE_SCAN);

          const result = await BarcodeScanner.startScan().catch((err) => {
            this.analyticsService.trackEvent(EVENT_NAMES.BARCODE_SCAN_ERROR, { error: err.message });
            throw new Error(`The barcode scanner failed.`);
          });

          if (result.hasContent && result.content.length > 1) {
            this.setIsValidating(true);
            const memberNumber = parseInt(result.content.toString().slice(8, 15));
            const memberNumberString = String(memberNumber);

            const authResponse = await this.barcodeValidationService.barcodeAuthenticate$Response().catch(() => {
              this.analyticsService.trackEvent(EVENT_NAMES.BARCODE_SCAN_ERROR, { error: 'Authentication failed' });
              throw new Error('Something went wrong validating your card.');
            });

            const validateResponse = await this.barcodeValidationService
              .barcodeValidate$Response(memberNumberString, authResponse.data.access_token)
              .toPromise()
              .catch(() => {
                this.analyticsService.trackEvent(EVENT_NAMES.BARCODE_SCAN_ERROR, { error: 'Validation failed' });
                throw new Error('Something went wrong validating your card.');
              });

            this.setIsValidating(false);

            if (validateResponse.body.memberStatus === 'Active') {
              await this.setBarcode(result.content);
              await this.setMemberNumber(memberNumberString);
              this.analyticsService.trackEvent(EVENT_NAMES.BARCODE_SCAN_SUCCESS);

              resolve();
            } else {
              this.analyticsService.trackEvent(EVENT_NAMES.BARCODE_SCAN_ERROR, { error: 'Card not active' });
              throw new Error('The scan did not return a valid card number.');
            }
          } else {
            this.analyticsService.trackEvent(EVENT_NAMES.BARCODE_SCAN_ERROR, {
              error: 'No card number returned from scan',
            });
            throw new Error('The scan did not return a card number.');
          }
        } else {
          this.analyticsService.trackEvent(EVENT_NAMES.BARCODE_SCAN_ERROR, { error: 'User denied camera permissions' });

          const { value } = await Dialog.confirm({
            title: 'Permissions required',
            message: "Camera permissions are required to scan your member card's barcode!",
            cancelButtonTitle: 'Close',
            okButtonTitle: 'Open settings',
          });

          this.closeBarcodeScanner();

          // value is true when the "open settings" button is pressed. This opens the native settings for this app, this is where the user can grant access again
          if (value) {
            await NativeSettings.open({
              optionAndroid: AndroidSettings.ApplicationDetails,
              optionIOS: IOSSettings.App,
            });
          }
        }
      } catch (err) {
        this.setIsValidating(false);

        if (typeof err === 'string') {
          this.setErrorMessage(err);
        } else {
          this.setErrorMessage(err.message);
        }

        reject(err.message);
      }
    });
  }

  closeBarcodeScanner() {
    document.body.classList.remove('scanner-active');
    this.stopScan();
  }

  watchBarcode(): Observable<string | undefined> {
    return this.barcode$;
  }

  watchMemberNumber(): Observable<string | undefined> {
    return this.memberNumber$;
  }

  stopScan() {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  }

  prepareForScan() {
    BarcodeScanner.prepare();
  }

  setIsValidating(isValidating: boolean): void {
    this.isValidating.next(isValidating);
  }

  getIsValidating(): Observable<boolean> {
    return this.isValidating.asObservable();
  }

  async getBarcode() {
    const barcode = await Preferences.get({ key: 'barcode' });
    return barcode;
  }

  async setBarcode(value) {
    await Preferences.set({ key: 'barcode', value });
    this.barcode$.next(value);
  }

  async removeBarcode() {
    await Preferences.remove({ key: 'barcode' });
    this.barcode$.next('');
  }

  async refreshBarcode() {
    const barcode = await this.getBarcode();
    await this.setBarcode(barcode.value);
    Promise.resolve();
  }

  async getMemberNumber() {
    const memberNumber = await Preferences.get({ key: 'member-number' });
    return memberNumber;
  }

  async setMemberNumber(value) {
    await Preferences.set({ key: 'member-number', value });
    this.memberNumber$.next(value);
  }

  async removeMemberNumber() {
    await Preferences.remove({ key: 'member-number' });
    this.memberNumber$.next('');
  }

  async refreshMemberNumber() {
    const memberNumber = await this.getMemberNumber();
    await this.setMemberNumber(memberNumber.value);
  }

  async refresh() {
    await this.refreshBarcode();
    await this.refreshMemberNumber();
  }

  setIsNewScan(v: boolean) {
    this.isNewScan.next(v);
  }

  getIsNewScan(): Observable<boolean | undefined> {
    return this.isNewScan.asObservable();
  }

  setErrorMessage(v: string) {
    this.errorMessage.next(v);
  }

  getErrorMessage(): Observable<string | undefined> {
    return this.errorMessage.asObservable();
  }
}
