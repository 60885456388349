import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { exhaustMap, map, switchMap } from 'rxjs/operators';
import { ReferenceDto } from '../api/models/reference-dto';
import { FuelTypeService } from '../api/services';
import { StorageKeys } from '../app.constants';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {
  
  constructor(
    private fuelTypeService: FuelTypeService,
    private localStorageService: LocalStorageService
  ) { }

  public getFuel$ = this.localStorageService.get(StorageKeys.fuelTypes).pipe(
    map(cache => (cache?.value as Array<ReferenceDto>)),
    switchMap(cached => {
      if (Boolean(cached)) {
        return of(cached);
      } else {
        return this.fuelTypeService.fuelTypeGetFuelTypeReferences().pipe(
          exhaustMap(refs => this.localStorageService.setWithExpiration(StorageKeys.fuelTypes, refs, 12 * 60 * 60 * 1000).pipe(
              map(() => refs)
            )
          )
        )
      }
    })
  )
}
