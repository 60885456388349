export const environment = {
  production: true,
  rootUrl: 'https://uat.api.fuelsaver.ract.com.au',
  authUrl: 'https://ract.au.auth0.com',
  memberCardValidationUrl: 'https://c01-aus.integrate-test.boomi.com',
  analyticsCode: 'G-RJ40B21VG4',
  authClientId: 'JK9nVZ777EV2KXEOcHmidtjciXCDl7Ao',
  authClientSecret: 'nyZIc8fJCBinzHQ1LFqp2bDqpxz1LkFb-ZYxNGyANKejgH94uEkMTXHqlXlZs6AM',
  authAudience: 'I4ChCfoZ4mGOfFa5tCsadQxyh3yu1OIp16T1T3l3',
  firebaseApiKey: 'AIzaSyBbI-ZGZz7HC60dS7Fg9u5Y_lQ7F3myHaY',
  firebaseMeasurementId: 'G-WP49W902RR',
  firebaseMessagingSenderId: '770112776271',
  firebaseAppId: '1:770112776271:web:2de353ee49ea722621e23a',
  validationAuth: 'c3ZjX21lbWJlcl92YWxpZGF0aW9uX3Rlc3RAcmFjdGx0ZC01Q09EVjguQ1FGM0VSOjdlZmRiMDJlLWI1OWQtNDQ4OS04MGIyLTM0NDA2Y2Q5MWM0YQ',
  validationPath: '/ws/rest/member_validation/currentstatus;boomi_user=svc_member_validation_test@ractltd-5CODV8.CQF3ER',
}