import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

/* eslint-disable no-multi-spaces */
@Injectable({
    providedIn: 'root'
})
/* eslint-enable no-multi-spaces */
export class LocalStorageService {
    constructor() { }

    get(key: string): Observable<any> {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) {
            return of(null);
        }

        return of(JSON.parse(itemStr));
    }

    set(key: string, value: any): Observable<boolean> {
        localStorage.setItem(key, JSON.stringify(value));

        return of(true);
    }

    remove(key: string): Observable<boolean> {
        localStorage.removeItem(key);

        return of(true);
    }

    clear(): Observable<boolean> {
        localStorage.clear();

        return of(true);
    }

    setWithExpiration(key: string, value: any, ttl: number): Observable<boolean> {
        const now = new Date();
        const item = {
            value:      value,
            expiration: now.getTime() + ttl
        };

        localStorage.setItem(key, JSON.stringify(item));

        return of(true);
    }

    getWithExpiration(key: string): Observable<{ value: any; expiration: Date}> {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) {
            return of(null);
        }

        const item = JSON.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiration) {
            localStorage.removeItem(key);

            return of(null);
        }

        return of(item);
    }
}
