import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';

import { CapacitorHttp } from '@capacitor/core';

interface AuthenticationResponse {
  data: {
    access_token: string;
    expires_in: number;
    token_type: string;
  };
}

interface ValidationResponse {
  body: {
    status: string;
    version: string;
    userMessage: string;
    memberStatus: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class BarcodeValidationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  static readonly BarcodeAuthenticatePath = '/oauth/token';

  barcodeAuthenticate$Response(): Promise<AuthenticationResponse> {
    return CapacitorHttp.request({
      method: 'POST',
      url: `${this.config.authUrl}/oauth/token`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        grant_type: 'client_credentials',
        client_id: this.config.clientId,
        client_secret: this.config.clientSecret,
        audience: this.config.authAudience,
      },
    });
  }

  barcodeValidate$Response(memberNumber: string, accessToken: string): Observable<ValidationResponse> {
    const rb = new RequestBuilder(this.config.memberCardValidationUrl, this.config.validationPath, 'post');

    rb.header('Authorization', `Basic ${this.config.validationAuth}`);
    rb.header('X-Auth-Token', `Bearer ${accessToken}`);

    rb.body({
      memberNumber,
    });

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(filter((r: any) => r instanceof HttpResponse));
  }
}
