import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiConfiguration } from '../api/api-configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient, private config: ApiConfiguration) { }

  public load(): Promise<boolean> {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      this.config.rootUrl = environment.rootUrl;
      this.config.authUrl = environment.authUrl;
      this.config.memberCardValidationUrl = environment.memberCardValidationUrl;
      this.config.clientId = environment.authClientId;
      this.config.clientSecret = environment.authClientSecret;
      this.config.authAudience = environment.authAudience;
      this.config.validationAuth = environment.validationAuth;
      this.config.validationPath = environment.validationPath;
      resolve(true);
    });
  }
}
