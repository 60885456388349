import { App } from '@capacitor/app';
import { Component } from '@angular/core';
import { ConfigurationService } from './api/services/configuration.service';
import { Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import { forkJoin, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BarcodeService } from './services/barcode.service';
import { LocalStorageService } from './services/local-storage.service';
import { Platforms, StorageKeys } from './app.constants';
import { ReferenceDto } from './api/models/reference-dto';
import { ReferenceService } from './services/reference.service';
import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private fuelTypes$: Observable<ReferenceDto[]>;
  private initConfig$: Observable<boolean>;

  public start$: Observable<boolean>;

  constructor(
    private barcodeService: BarcodeService,
    private platform: Platform,
    private configService: ConfigurationService,
    private localStorageService: LocalStorageService,
    private referenceService: ReferenceService,
    private analyticsService: AnalyticsService
  ) {
    this.initializeApp();
  }

  public isBarcodeValidating$: Observable<boolean> = this.barcodeService.getIsValidating();

  initializeApp() {
    this.fuelTypes$ = this.referenceService.getFuel$;
    this.initConfig$ = this.initConfig();
    const obs = [this.initConfig$, this.fuelTypes$];
    if (this.platform.is('android')) {
      this.platform.backButton.subscribeWithPriority(-1, () => {
        App.exitApp();
      });
    }
    this.start$ = forkJoin(obs).pipe(
      switchMap(async (result) => {
        if (result) {
          if (this.platform.is('hybrid')) {
            await SplashScreen.hide();
          }
          return true;
        }
        return false;
      })
    );
  }

  initConfig(): Observable<boolean> {
    return from(this.platform.ready()).pipe(
      map(() => {
        if ((this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
          return Platforms.device;
        } else {
          return Platforms.web;
        }
      }),
      switchMap((platform) =>
        this.configService
          .configurationGetConfiguration({ platform })
          .pipe(switchMap((key) => this.localStorageService.set(StorageKeys.googleMapsApiKey, key.googleMapsApiKey)))
      )
    );
  }

  closeBarcodeScanner() {
    document.body.classList.remove('scanner-active');
    this.barcodeService.stopScan();
  }
}
