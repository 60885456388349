import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private platform: Platform) {
    this.initialiseFirebase();
  }

  async initialiseFirebase() {
    // Initialises firebase analytics if platform is web
    if (!this.platform.is('ios') && !this.platform.is('android')) {
      await FirebaseAnalytics.initializeFirebase({
        apiKey: environment.firebaseApiKey,
        authDomain: 'ract-firebase-analytics.firebaseapp.com',
        projectId: 'ract-firebase-analytics',
        storageBucket: 'ract-firebase-analytics.appspot.com',
        messagingSenderId: environment.firebaseMessagingSenderId,
        appId: environment.firebaseAppId,
        measurementId: environment.firebaseMeasurementId,
      });
    }

    await FirebaseAnalytics.setCollectionEnabled({ enabled: true });
  }

  trackEvent(name, params?) {
    FirebaseAnalytics.logEvent({
      name,
      params,
    });
  }
}
